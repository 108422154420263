import {Component, effect, inject} from '@angular/core'
import {MatIconButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer'
import {OrientationService} from '../../services/orientation.service'
import {PdfProxyService} from '../../services/pdf-proxy.service'

@Component({
  selector: 'spb-pdf-viewer',
  imports: [MatIcon, MatIconButton, NgxExtendedPdfViewerModule],
  templateUrl: './pdf-viewer.component.html',
  styleUrl: './pdf-viewer.component.scss'
})
export class PdfViewerComponent {
  public orientationService = inject(OrientationService)
  public proxyService = inject(PdfProxyService)
  public isPdfLoaded = false
  protected src = ''
  protected base64Src = ''


  constructor() {
    effect(() => {
      this.isPdfLoaded = false

      this.src = ''
      this.base64Src = ''

      if (this.orientationService.pdfUrl$().includes('./assets/links/')) {
        this.src = this.orientationService.pdfUrl$()
        this.isPdfLoaded = true
      } else if (this.orientationService.pdfUrl$()) {
        this.base64Src = ''
        this.proxyService
          .getProxiedPdfUrl(this.orientationService.pdfUrl$())
          .subscribe(response => {
            this.base64Src = response.body
            this.isPdfLoaded = true
          }
          )
      }
    })
  }
}