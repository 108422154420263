<div class="header">
  <spb-logo [routePath]="['/']"
            [title]="configService.onInternalRoute ? 'Fondväljaren':''"></spb-logo>

  @if ((configService.logInState$ | async); as logInState) {
    <div class="menu">
      <span>{{ logInState.name }}</span>
      <button [matMenuTriggerFor]="menu" mat-button>
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  }
</div>

<!-- Add other fancy navigations here -->
<mat-menu #menu="matMenu">
  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
</mat-menu>
