@if (orientationService.pdfUrl$()) {
  <div class="pdf-viewer-container" [class.pdf-loaded]="isPdfLoaded">
    <div class="pdf-header">
      @if (!base64Src && !src) {
        <p class="loading-text">Laddar, vänligen vänta...</p>
      }
      <button mat-icon-button (click)="orientationService.pdfUrl$.set('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ngx-extended-pdf-viewer
      [base64Src]="base64Src"
      [src]="src"
      language="sv"
    >
    </ngx-extended-pdf-viewer>
  </div>
}
