import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {ApplicationConfig, inject, provideAppInitializer} from '@angular/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {provideRouter, Router, withHashLocation} from '@angular/router'
import routes from './app-routes'
import {authInterceptor} from './application/auth.interceptor'
import {WINDOW, windowProvider} from './application/injection-tokens'
import {responseInterceptor} from './application/response.interceptor'
import {routeResolver} from './application/route-resolver'
import {ConfigService} from './services/config.service'

registerLocaleData(localeFr, 'fr', localeFrExtra)

export const appConfig: ApplicationConfig = {
  providers: [
    {provide: WINDOW, useFactory: windowProvider},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    },
    /**
     * Conditionally initializes `bootstrap` only for the `/admin` route,
     */
    provideAppInitializer(() => {
      return routeResolver(inject(ConfigService), inject(Router), inject(WINDOW))
    }),
    provideHttpClient(
      withInterceptors([authInterceptor, responseInterceptor])
    ),
    provideAnimationsAsync(),
    provideRouter(routes,
      withHashLocation())
  ]
}