{
  "name": "simple-savings-guide",
  "version": "2.4.5-develop.0",
  "scripts": {
    "watch": "ng test",
    "build": "./scripts/version.sh && ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost-internal.sparbanken.me",
    "style-lint": "stylelint  src/**/*.scss --fix",
    "test": "ng test --watch=false --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.4",
    "@angular/common": "^19.2.4",
    "@angular/compiler": "^19.2.4",
    "@angular/core": "^19.2.4",
    "@angular/forms": "^19.2.4",
    "@angular/platform-browser": "^19.2.4",
    "@angular/platform-browser-dynamic": "^19.2.4",
    "@angular/router": "^19.2.4",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.5",
    "@angular-devkit/core": "^19.2.5",
    "@angular-devkit/schematics": "^19.2.5",
    "@angular/cdk": "^19.2.7",
    "@angular/cli": "~19.2.5",
    "@angular/compiler-cli": "^19.2.4",
    "@angular/material": "^19.2.7",
    "@sparbanken-syd/auth-backend": "next",
    "@sparbanken-syd/personnummer": "^3.0.1",
    "@sparbanken-syd/simple-savings-guide-be": "^2.2.1",
    "@sparbanken-syd/sparbanken-syd-bankid": "next",
    "@sparbanken-syd/sparbanken-syd-qr": "next",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.3",
    "@sparbanken-syd/teradata-user": "^2.7.1",
    "@types/jasmine": "~5.1.7",
    "@types/sinon": "^17.0.4",
    "@types/sparbanken-syd-abasec": "^3.1.6",
    "@types/sparbanken-syd-auth-backend": "^2.3.0",
    "@types/sparbanken-syd-document-service": "^1.4.2",
    "angular-eslint": "^19.3.0",
    "eslint": "^9.23.0",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ngx-extended-pdf-viewer": "^22.3.9",
    "postcss": "^8.5.3",
    "sinon": "^20.0.0",
    "stylelint": "^16.17.0",
    "stylelint-config-sass-guidelines": "^12.1.0",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-scss": "^6.11.1",
    "typescript": "~5.8.2",
    "typescript-eslint": "^8.28.0"
  }
}
